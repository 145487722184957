import consumer from "./consumer"

consumer.subscriptions.create("ResumeChannel", {
  connected() {
  	console.log('Vous êtes connectés au résumé')
    // Called when the subscription is ready for use on the server
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
  },

  received(data) {
	// Called when there's incoming data on the websocket for this channel
    if (data['title']=='energy'){
		  window.location.href = "result_game?game="+data['body'];
    }
    else if (data['title']=='energy_game'){
      window.location.assign("http://"+window.location.hostname+"/workshops/"+data['workshop_id']+"/game_energy?game="+data['body']);
    } 
    else if (data['title']=='energy_game_bis'){
      window.location.assign("http://"+window.location.hostname+"/workshops/"+data['workshop_id']+"/game"+data['body']+'_energy');
    } 
    else if (data['title']=='water'){
      window.location.assign("http://"+window.location.hostname+"/workshops/"+data['workshop_id']+"/game"+data['body']+'_water');
    }
    else if (data['title']=='water_answer'){
      window.location.assign("http://"+window.location.hostname+"/workshops/"+data['workshop_id']+"/answer"+data['body']+'_water');
    }
    else if (data['title']=='zw'){
      window.location.href = "result_game_zw?game="+data['body'];
    }    
    else if (data['title']=='zw_game'){
      window.location.assign("http://"+window.location.hostname+"/workshops/"+data['workshop_id']+"/game"+data['body']);
    }    
	},
  });
import consumer from "./consumer"

consumer.subscriptions.create("WaterChannel", {
  connected() {
  	console.log('Vous êtes connectés aux autres participants')
    // Called when the subscription is ready for use on the server
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
  },

  received(data) {
	// Called when there's incoming data on the websocket for this channel
      if (data.broadcast_action == "found"){
          $('.card-'+data.card_id).remove();
          if ($('.found-card-'+data.card_id)[0]){
          }
          else{
            $('.water-'+data.rectangle_value).children().eq(1).append(this.renderFoundCard(data));
            this.drag(data);
          }
      }
      if (data.broadcast_action == "delete"){
        $('.card-'+data.card_id).remove();
      }
      if (data.broadcast_action == "add"){
        $('.card-'+data.card_id).remove();
        $('.all_cards').append(this.renderCard(data));
        this.drag(data);
      }     
      if (data.broadcast_action == "real_time_water")
      {
        var card_class = data.card_class.split(' ')[1];
        var window_width = $(window).width();
        var window_height = $(window).height();
        var value_left = parseFloat(data.left)*window_width;
        var value_top = parseFloat(data.top)*window_height;
          console.log('left: '+value_left+', top: '+value_top);
        $('.'+card_class).css({left: value_left+'px', top: value_top+'px', position: 'relative'});
        $('.'+card_class +' img').css({position: 'absolute'});
      }
    },
    renderCard: function(data) {
       return "<div class='card card-"+data.card_id+" team-"+data.team_id+"' draggable='true' id='draggable-1'><img src="+data.card_url+"></div>";
    },
    renderFoundCard: function(data) {
      return "<div class='card card-"+data.card_id+"'><img src="+data.card_url+"></div>";
    },
    drag: function(data){
      $( ".card" ).each(function( index ) {
        $(this).draggable();
      });

      $( ".rectangle_answer" ).each(function( index ) {
        $(this).droppable({
          drop: function( event, ui ) {
            var card_id = ui.draggable.attr("class").split('card-')[1].split(' ')[0]
            var rectangle_value = $(this).attr("class").split('water-')[1].split(' ')[0];
            $.ajax({
              url: "/cards/drop",
              type: "POST",
              data : {
                card_id: card_id,
                rectangle_value: rectangle_value,
                broadcast_function: 'found'
              },
              dataType: "script",
            });    
          }
        }); 
      });    
      $( ".all_cards_water" ).droppable({
        drop: function( event, ui ) {
          console.log("in");
          var card_id = ui.draggable.attr("class").split('card-')[1].split(' ')[0]
          $.ajax({
            url: "/cards/drop",
            type: "POST",
            data : {
              card_id: card_id,
              broadcast_function: 'add'
            },
            dataType: "script",
          });    
        }
      });  
    }
  });
import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="transportation-calculator"
export default class extends Controller {
  new_playerAdd(event){
    const joueurs = document.querySelector('#joueurs');
    const index = document.querySelectorAll('.joueur').length + 1;
    document.querySelector('#number_of_players').value = index;
    
    const joueur = document.querySelectorAll('.joueur')[0];
    const newJoueur = joueur.cloneNode(true);
    const carElement = joueur.querySelector('.number_passenger_footprint');

    console.log("index1 :"+index);
    // Nettoyer les champs de saisie clonés
    newJoueur.querySelectorAll('input').forEach(input => {
      // Extraire le nom de l'input existant
      let inputName = input.name;
      // Modifier le nom pour rendre l'index dynamique
      inputName = inputName.replace(/\[(\d+)\]/, function(match, p1) {
        // Incrémenter l'index pour le prochain joueur
        return '[' + (parseInt(p1) + (parseInt(index)-1)) + ']';
      });
      // Affecter le nouveau nom modifié à l'input cloné
      input.name = inputName;
      // Réinitialiser la valeur du champ de saisie
      input.value = '';
    });
    newJoueur.querySelectorAll('select').forEach(select => {
      // Extraire le nom de l'input existant
      let selectName = select.name;
      // Modifier le nom pour rendre l'index dynamique
      selectName = selectName.replace(/\[(\d+)\]/, function(match, p1) {
        // Incrémenter l'index pour le prochain joueur
        return '[' + (parseInt(p1) + (index-1)) + ']';
      });
      // Affecter le nouveau nom modifié à l'input cloné
      select.name = selectName;   
    })
    newJoueur.querySelector('.joueur span').textContent = 'Joueur ' + index;
    if (carElement){
      newJoueur.querySelector('.number_passenger_footprint').remove();
    }
    console.log("index test :"+joueur.querySelector('.player-counter').getAttribute('data-player'));
    newJoueur.querySelector('.player-counter').dataset.player = index
    // Ajouter le nouvel élément joueur à la liste des joueurs
    joueurs.appendChild(newJoueur);
  }

  new_player(event){
    const joueurs = document.querySelector('#joueurs');
    const index = joueurs.querySelectorAll('.joueur').length + 1;
    document.querySelector('#number_of_players').value = index;
    const newJoueur = document.createElement('div');
    newJoueur.classList.add('joueur');
    newJoueur.innerHTML = `
        <div class="nb_joueur"><span>Joueur ${index}</span></div>
        <label class="transportation_footprint_label" for="transportation_footprint_joueur_${index}_kilometers">
            Nombre de kilomètres parcourus pour venir sur ce lieu
        </label>
        <br/>
        <input type="number" name="transportation_footprint[transportation_footprints][${index}][kilometers]"/>
        <br/>
        <br/>
        <label>Moyen de transport principal: </label>
        <select class="means_of_transportations" name="transportation_footprint[transportation_footprints][${index}][means_of_transportation]" >
            <option value="bus">Bus</option>
            <option value="car">Voiture</option>
            <option value="electrical_car">Voiture électrique</option>
            <option value="TGV">TGV</option>
            <option value="ter">TER</option>
            <option value="plane">Avion</option>
            <option value="metro">Métro</option>
            <option value="tramway">Tramway</option>
            <option value="bike">Vélo</option>
            <option value="foot">À pied</option>
            <option value="electrical_bike">Vélo électrique</option>
        </select>
        <br/>
        <div class="buttons_delete_duplicate">
            <div class="duplicate"><span data-action"="click->transportation-calculator#addDuplicate">Dupliquer</span></div>
            <div class="delete"><span data-action"="click->transportation-calculator#deleteJoueur">Supprimer</span></div>
        </div>`;
    joueurs.appendChild(newJoueur);
  }

  means_of_transportation(event){
    var selectContainer = null;
    var selectedOption = null;
    if (event.target!=undefined){
      selectContainer = event.target; // Sélection de l'élément parent du select
      selectedOption = event.target.value;
    }
    else{
      selectContainer = event; // Sélection de l'élément parent du select
      selectedOption = event.value;
      console.log("ok");
    }
    const joueurs = document.querySelector('#joueurs');
    const index = joueurs.querySelectorAll('.joueur').length;
    console.log(selectContainer.parentElement);
    const position = selectContainer.parentElement.querySelector('.player-counter').getAttribute('data-player');
    const carElement = selectContainer.parentElement.querySelector('.number_passenger_footprint');
    console.log(carElement);
    // Vérification de la sélection "Car"
    if (selectedOption === 'car' || selectedOption === 'electrical_car') {
      if (carElement == null){
        const nouvelElement = document.createElement('div');
        nouvelElement.classList.add('number_passenger_footprint');
        nouvelElement.innerHTML = `
            <br/>
            <br/>
            <label class="transportation_footprint_label">Nombre de personnes dans la voiture</label>
            <br/>
            <input type="number" name="transportation_footprint[transportation_footprints][${position}][number_of_passengers]">
        `;
        
        // Ajout de nouvelElement après le select
        selectContainer.insertAdjacentElement('afterend', nouvelElement);
      }
    }
    else{
        if (carElement) {
          carElement.remove();
      }
    }
  }


  deleteJoueur(event) {
      const joueurs = document.querySelector('#joueurs');
      const index = joueurs.querySelectorAll('.joueur').length;
      document.querySelector('#number_of_players').value = index-1;
      if (index === 1) {
          alert('Vous êtes obligé de rentrer les valeurs pour au moins un joueur');
      } else {
          if (confirm('Êtes-vous sûr de vouloir supprimer ce joueur ?')) {
              const selectedElementParent = event.target.closest('.joueur');
              selectedElementParent.remove();

              // Mise à jour des numéros de tous les joueurs
              $('.joueur').each(function(index){
                $(this).querySelector('.joueur span').textContent = 'Joueur ' + (index);
              });
          }
      }
  }

  addDuplicate(event) {
      const joueurs = document.querySelector('#joueurs');
      const index = joueurs.querySelectorAll('.joueur').length + 1;
      const joueur = event.target.closest('.joueur');
      const newJoueur = joueur.cloneNode(true);
      console.log("avant "+ document.querySelector('#number_of_players').value);
      document.querySelector('#number_of_players').value = index;
      console.log("apres "+ document.querySelector('#number_of_players').value);
      newJoueur.querySelector('.joueur span').textContent = 'Joueur ' + index;
      if (joueur.querySelector('select') != null){
        const means_of_transportation = joueur.querySelector('select').value;
        newJoueur.querySelector('select').value = means_of_transportation;
      }
      newJoueur.querySelectorAll('input').forEach(input => {
        console.log("addDuplicate index :"+index);
        // Extraire le nom de l'input existant
        let inputName = input.name;
        // Modifier le nom pour rendre l'index dynamique
        inputName = inputName.replace(/\[(\d+)\]/, function(match, p1) {
          // Incrémenter l'index pour le prochain joueur
          return '[' + (parseInt(index)) + ']';
        });
        // Affecter le nouveau nom modifié à l'input cloné
        input.name = inputName;
      });
      newJoueur.querySelectorAll('select').forEach(select => {
        console.log("addDuplicate index2 :"+index);
        // Extraire le nom de l'input existant
        let selectName = select.name;
        // Modifier le nom pour rendre l'index dynamique
        selectName = selectName.replace(/\[(\d+)\]/, function(match, p1) {
          // Incrémenter l'index pour le prochain joueur
          return '[' + parseInt(index) + ']';
        });
        // Affecter le nouveau nom modifié à l'input cloné
        select.name = selectName;   
      })
      newJoueur.querySelector('.player-counter').dataset.player = index
      newJoueur.querySelector('.transport_id').remove();
      // Ajouter le nouvel élément joueur à la liste des joueurs
      joueurs.appendChild(newJoueur);
  }

  connect() {
    this.element.addEventListener('click', (event) => {
      if (event.target.classList.contains('duplicate')) {
          this.addDuplicate(event.target.parentElement.parentElement);
      }
      if (event.target.classList.contains('delete')) {
          this.deleteJoueur(event.target.parentElement.parentElement);
      }

    });
    this.element.addEventListener('change', (event) => {
      if (event.target.classList.contains('means_of_transportations')) {
          this.means_of_transportation(event.target);
      }        
    });
  };
};

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  	addColor(event){
		let y = document.getElementById("table-result").rows[0];
		let x = y.insertCell(-1)
		let color = event.target.id
		x.style.background = color
		x.classList.add(color)
		x.classList.add("answer")
		x.classList.add(y.cells.length)
		x.style.height = "30px"
  	}

	clear(event) {
	    document.querySelector('.answer_tr').innerHTML = ""
	 }

	sendTeam(event) {
		let user_name = document.getElementById("user_name").value;
		let team_name = document.getElementById("team_name").value;
		let firm_link_id = document.getElementById("firm_link").value;
		let form_token = document.getElementById("form_token").value;
		fetch("/send_team", {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify({ firm_link_id: firm_link_id, team_name: team_name, user_name: user_name, form_token: form_token }),
			})
		.then(response => response.text())
		.then(script => {
		  const scriptElement = document.createElement('script');
		  scriptElement.text = script;
		  document.body.appendChild(scriptElement).parentNode.removeChild(scriptElement);
		})
		.catch(error => console.error('Error:', error));			
	}

	sendColor(event) {
		let answers = Array.from(document.getElementsByClassName("answer")).map(
		  (el) => el.classList.item(0)
		)
		let id = window.location.pathname.split('/').pop()
		fetch("/answer_color", {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify({ id: id, answer: answers }),
			})
		.then(response => response.text())
		.then(script => {
		  const scriptElement = document.createElement('script');
		  scriptElement.text = script;
		  document.body.appendChild(scriptElement).parentNode.removeChild(scriptElement);
		})
		.catch(error => console.error('Error:', error));
	}

	addDigit(event){
		let y = document.getElementById("table-result").rows[0];
		let x = y.insertCell(-1)
		let digit = event.target.getHTML().replace(/(\r\n|\n|\r)/gm, "");;
		x.classList.add(parseInt(digit));
		x.classList.add("answer-digit");
		x.classList.add(y.cells.length)
		x.style.border="1px solid black";
		x.style='border-radius:5px;height:20px;border: 1px solid black;text-align: center;';
		x.textContent=digit;
	}

	sendDigit(event){
		let answers = Array.from(document.getElementsByClassName("answer-digit")).map(
		  (el) => el.classList.item(0)
		)
		let id = window.location.pathname.split('/').pop()
		fetch("/answer_digits", {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify({ id: id, answer: answers }),
			})
		.then(response => response.text())
		.then(script => {
		  const scriptElement = document.createElement('script');
		  scriptElement.text = script;
		  document.body.appendChild(scriptElement).parentNode.removeChild(scriptElement);
		})
		.catch(error => console.error('Error:', error));		
	}

	sendText(event){
		let pwd = document.getElementById("mdp").value;
		let id = window.location.pathname.split('/').pop()
		fetch("/pwd_value", {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify({ id: id, pwd: pwd }),
			})
		.then(response => response.text())
		.then(script => {
		  const scriptElement = document.createElement('script');
		  scriptElement.text = script;
		  document.body.appendChild(scriptElement).parentNode.removeChild(scriptElement);
		})
		.catch(error => console.error('Error:', error));	
	}

	clearText(event){
		document.getElementById("mdp").value="";
	}
}

import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="admin"
export default class extends Controller {
  connect() {
    // document.getElementById('card_workshop_name_id').addEventListener('change', function () {
    // var choosen = $( "#card_workshop_name_id option:selected" ).text();
    // var style = choosen == 'atelier_zero_dechet' ? 'block' : 'none';
    // var style2 = choosen == 'atelier_rse' ? 'block' : 'none';
    // var style3 = choosen == 'atelier_eau' ? 'block' : 'none';
    // console.log(choosen);
    // document.getElementById('select_card').style.display = style2;
    // document.getElementById('theme_card_zd').style.display = style;
    // document.getElementById('theme_card_water').style.display = style3;
    // });
  };
  reload(event) {
    if (event.detail.fetchResponse.redirected) {
      window.location.href = event.detail.fetchResponse.response.url;
    }
  }
}

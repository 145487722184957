import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  new_player(){
    alert('test');
    console.log('test');
  }
  connect() {
  }
}

import consumer from "./consumer"

consumer.subscriptions.create("CarbonFootprintChannel", {
  connected() {
  	console.log('Vous êtes connectés à empreinte carbone')
    // Called when the subscription is ready for use on the server
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
  },

  received(data) {
	// Called when there's incoming data on the websocket for this channel
    if (data.total != null){
      $('.total_value').html(data.total);
	  }
    if (data.housing != null){
      $('.housing_value').html(data.housing);
    }
    if (data.divers != null){
      $('.divers_value').html(data.divers);
    }
    if (data.feed != null){
      $('.feed_value').html(data.feed);
    }
    if (data.digital != null){
      $('.digital_value').html(data.digital);
    }
    if (data.transportation != null){
      $('.transportation_value').html(data.transportation);
    }
  }

  });